import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { ImageGrid } from "../components/ImageGrid"
import { Hero } from "../components/Hero"
import { TextColumns } from "../components/TextColumns"
import { StyledFlex } from "../components/StyledFlex"
import { Image } from "../components/Image"

const incarcerationImage = number => `incarceration-${number}.jpg`

const IncarcerationPage = () => {
  return (
    <Layout isProjectPage>
      <SEO />

      <Hero>
        Reconstructing Incarceration
        <Image src={"incarceration-hero"} />
      </Hero>

      {[...Array(45)].map((image, index) => (
        <Image
          style={{ margin: "80px 0px" }}
          src={incarcerationImage(index + 1)}
        />
      ))}
    </Layout>
  )
}

export default IncarcerationPage
