import React from "react"
import styled from "styled-components"

type StyledFlexProps = {
  align: "flex-start" | "flex-end" | "center"
}

export const StyledFlex = styled.div<StyledFlexProps>`
  width: 100%;
  height: 100;
  display: flex;
  align-items: ${props => props.align || "flex-start"};
`
