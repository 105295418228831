import React from "react"
import styled from "styled-components"

type ImageGridProps = {
  children: HTMLElement
  margin: string
}

type StyledGridProps = {
  margin: string
}

export const ImageGrid = (props: ImageGridProps) => {
  return <StyledGrid {...props}>{props.children}</StyledGrid>
}

const StyledGrid = styled.div<StyledGridProps>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  grid-gap: 40px;
  margin: ${props => (props.margin ? props.margin : "160px 0px")};

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 32px;
    margin: 100px 0px;
  }
`
